import ClickAway from '@material-ui/core/ClickAwayListener';
import React, { FC } from 'react';

interface Props {
  onClickAway: () => void;
  visible: boolean;
  className?: string;
}

export const ClickAwayListener: FC<Props> = ({
  onClickAway,
  visible,
  className,
  children,
}) => visible
  ? (
    <div className={className}>
      <ClickAway onClickAway={onClickAway}>
        <div>
          {children}
        </div>
      </ClickAway>
    </div>
  )
  : null;
