/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { MoreHorizontal, Trash2 } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../button/Button.component';
import { ClickAwayListener } from '../click-away-listener';
import { Text, TextTypeEnum } from '../text/Text.component';
import './MultiActionButton.scss';

export enum MultiActionButtonOptionComponentsEnum {
  TEXT,
  HR,
  COMPONENT
}

export enum MultiActionButtonOptionTypeEnum {
  DELETE
}
declare interface IMultiActionButtonOptions {
  component: MultiActionButtonOptionComponentsEnum;
  disabled?: boolean;
  onClick?: () => void;
  value?: string | React.FC<{onClick: () => void}>;
  type?: MultiActionButtonOptionTypeEnum;
}
declare interface IMultiActionButtonProps {
  disabled?: boolean;
  className?: string;
  small?: boolean;
  transparent?: boolean;
  noButton?: boolean;
  options: IMultiActionButtonOptions[];
  tabIndex?: number;
  type?: 'PRIMARY' | 'SECONDARY' | 'ERROR';
}

const OPEN_POPUP_CLICK_EVENT = 'openPopupClick';

export const MultiActionButton: React.FC<IMultiActionButtonProps> = ({
  disabled, className, small, options, transparent, children, noButton, type, tabIndex = 0
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const uuidRef = useRef(uuidv4());

  const popupClickEventHandler = useCallback((e: Event) => {
    const evt = e as CustomEvent<{ uuid: string }>;
    if (evt.detail.uuid !== uuidRef.current) {
      setPopupOpen(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener(OPEN_POPUP_CLICK_EVENT, popupClickEventHandler);
    return () => window.removeEventListener(OPEN_POPUP_CLICK_EVENT, popupClickEventHandler);
  }, []);

  const openPopup = () => {
    window.dispatchEvent(new CustomEvent(
      OPEN_POPUP_CLICK_EVENT,
      { detail: { uuid: uuidRef.current }}
    ));

    setPopupOpen(true);
  };

  const onMenuSelection = (onClick?: () => void) => () => {
    void onClick?.();
    setPopupOpen(false);
  };

  const noop = (e: { stopPropagation: () => void;}) => {
    e.stopPropagation();
  };
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={onClick}
      className={
        classNames(
          'MultiAction__wrapper',
          {
            'MultiAction__wrapper-transparent': transparent,
            'MultiAction__wrapper-withChildren': !!children,
            'MultiAction__wrapper-small': small
          },
          className,
        )
      }
    >
      { noButton
        ? (
          <div
            onClick={disabled ? undefined : openPopup}
            className="MultiAction__noButtonDiv"
            role="menuitem"
            tabIndex={0}
          >
            {children}
          </div>
        )
        : (
          <Button icon type={type || (children ? 'PRIMARY' : 'SECONDARY')} disabled={disabled} tabIndex={tabIndex} small={small} onClick={disabled ? undefined : openPopup}>
            {children || <MoreHorizontal className="MultiAction__icon" />}
          </Button>
        )}
      <ClickAwayListener className="MultiAction__popup" visible={popupOpen} onClickAway={() => setPopupOpen(false)}>
        {options.map(({
          component, type, value, onClick, disabled: optionDisabled,
        }) => {
          switch (component) {
            case MultiActionButtonOptionComponentsEnum.TEXT:
              if (type === MultiActionButtonOptionTypeEnum.DELETE) {
                return (
                  <div
                    key={value as string}
                    className={classNames('MultiAction__option-delete', { 'MultiAction__option-disabled': optionDisabled })}
                    onClick={optionDisabled ? noop : onMenuSelection(onClick)}
                    role="menuitem"
                    tabIndex={0}
                  >
                    <Text type={TextTypeEnum.INPUT_LABEL}>{value}</Text>
                    <Trash2 size={16} />
                  </div>
                );
              }
              return <Text key={value as string} className={classNames('MultiAction__option', { 'MultiAction__option-disabled': optionDisabled })} onClick={optionDisabled ? noop : onMenuSelection(onClick)} type={TextTypeEnum.INPUT_LABEL}>{value}</Text>;

            case MultiActionButtonOptionComponentsEnum.HR:
              return <hr key={value as string} className="MultiAction__hr" />;
            case MultiActionButtonOptionComponentsEnum.COMPONENT:
              const Comp = value as React.FC<{onClick: () => void}>
              return <Comp onClick={() => setPopupOpen(false)} />;
            default:
              return <Text key={value as string} className={classNames('MultiAction__option', { 'MultiAction__option-disabled': optionDisabled })} onClick={optionDisabled ? noop : onMenuSelection(onClick)} type={TextTypeEnum.INPUT_LABEL}>{value}</Text>;
          }
        })}
      </ClickAwayListener>
    </div>
  );
};
