import React, { useState } from 'react';
import {
  GridComponent, IGridProps, IRowData, RowDataType, SortDirection,
} from './Grid.component';
import './Grid.scss';

export function Grid<T = { label?: string; id?: string; }>(props: IGridProps<T>) {
  const [search, setSearch] = useState('');
  const [sortColumn, setSortColumn] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizeSelectorOpen, setPageSizeSelectorOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const hasNoData = !props.rows.length;

  const onSearch = (e?: React.ChangeEvent<HTMLInputElement>) => {
    setSearch((e?.target?.value || '').toLowerCase());
  };
  const onColumnClick = (index: number) => (_: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setSortColumn(index);
    setSortDirection((sortDirection) => sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC);
  };
  const incrementPage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setPageIndex((pageIndex) => pageIndex + 1);
  };
  const decrementPage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setPageIndex((pageIndex) => pageIndex - 1);
  };
  const openPageSizeSelector = () => {
    setPageSizeSelectorOpen(true);
  };
  const closePageSizeSelector = () => {
    setPageSizeSelectorOpen(false);
  };
  const handlePageSizeClick = (size: number) => (_: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => {
    setPageSize(size);
    setPageSizeSelectorOpen(false);
  };
  const filteredRows = props.rows.filter(({ columnData }) => (
    columnData.some(({ data = {} }) => (data as IRowData).label?.toLowerCase().includes(search))
  ));
  const sortedRows = filteredRows.sort((a, b) => {
    let bothEqual: boolean;
    let aIsBigger: boolean;
    const sortedColumnDataType = props.columnDefs.headers[sortColumn].type;
    const rawAValue = (a.columnData[sortColumn].data as IRowData).label;
    const rawBValue = (b.columnData[sortColumn].data as IRowData).label;
    if (sortedColumnDataType === RowDataType.DATE) {
      const aDate = new Date(rawAValue || '');
      const bDate = new Date(rawBValue || '');
      bothEqual = aDate === bDate;
      aIsBigger = aDate > bDate;
    } else {
      const defaultValue = sortedColumnDataType === RowDataType.STRING ? '' : -Infinity;
      let aValue = rawAValue || defaultValue;
      let bValue = rawBValue || defaultValue;
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        aValue = aValue.toLocaleLowerCase();
        bValue = bValue.toLocaleLowerCase();
      }
      bothEqual = aValue === bValue;
      aIsBigger = aValue > bValue;
    }
    if (bothEqual) return 0;
    if (sortDirection === SortDirection.DESC) return aIsBigger ? -1 : 1;
    return aIsBigger ? 1 : -1;
  });

  let currentPageIndex = pageIndex;
  let pageOffset = currentPageIndex * pageSize;
  let paginatedRows = sortedRows.slice(pageOffset, pageOffset + pageSize);
  // If the current page is empty, find a previous page with data
  while (!paginatedRows.length && currentPageIndex > 0) {
    --currentPageIndex;
    pageOffset = currentPageIndex * pageSize;
    paginatedRows = sortedRows.slice(pageOffset, pageOffset + pageSize);
  }
  // If a previous page is found, update page index
  if (currentPageIndex !== pageIndex) {
    setPageIndex(currentPageIndex);
  }
  return (
    <GridComponent<T>
      {...props}
      key={pageIndex}
      rows={paginatedRows}
      onSearch={onSearch}
      sortColumn={sortColumn}
      onColumnClick={onColumnClick}
      sortDirection={sortDirection}
      total={sortedRows.length}
      pageOffset={pageOffset}
      incrementPage={incrementPage}
      decrementPage={decrementPage}
      pageSize={pageSize}
      handlePageSizeClick={handlePageSizeClick}
      pageSizeSelectorOpen={pageSizeSelectorOpen}
      openPageSizeSelector={openPageSizeSelector}
      closePageSizeSelector={closePageSizeSelector}
      hasNoData={hasNoData}
      of={props.of ?? 'Data'}
    />
  );
}
