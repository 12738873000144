/**
 * Creates a debounced function that delays invoking `func` until after `wait`
 * milliseconds have elapsed since the last time the debounced function was
 * invoked, or until the next browser frame is drawn. The latest args sent to the
 * `func` will be what is eventually passed to the invoked function
 *
 * @since 0.0.1
 * @category Function
 * @param {Function} func The function to debounce.
 * @param {number} [wait=0]
 *  The number of milliseconds to delay;
 * @returns {Function} Returns the new debounced function.
 * @example
 *
 * //Debounce input changes
 * const debouncedOnChange = debounce<React.ChangeEvent<HTMLInputElement>>(onChange, 100);
 *
 */

export function debounce<T = any>(func: (args?: T) => void, wait: number = 0) {
  let timeOut: ReturnType<typeof setTimeout>;
  return (args?: T) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }

    // clone to ensure latest
    let clonedArgs = args;
    if (clonedArgs && typeof clonedArgs === 'object') {
      clonedArgs = { ...args } as T;
    }
    timeOut = setTimeout(() => {
      func(clonedArgs);
    }, wait);
  };
}
