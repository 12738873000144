import React from 'react';
import classNames from 'classnames';
import { Text, TextTypeEnum } from '../text/Text.component';
import './Avatar.scss';
import { Image } from 'react-feather';

interface Props {
  className?: string;
  image?: string;
  size: string | number;
  name?: string;
  textType: TextTypeEnum;
  active?: boolean;
  onClick?: () => void;
}

export const Avatar: React.FC<Props> = ({
  className, image, name, size, textType, active, onClick,
}) => (
  image
    ? <img onClick={onClick} style={{ width: size, height: size }} className={classNames('Avatar__image', { active }, className)} src={image} />
    : (
      <div onClick={onClick} style={{ width: size, height: size }} className={classNames('Avatar__divWrapper', { active }, className)}>
        <div style={{ width: size, height: size }} className="Avatar__div">
          {name ? <Text type={textType} hoverLabel={name}>{name[0]?.toUpperCase()}</Text> : <Image className="Avatar__imagePlaceholder" /> }
        </div>
      </div>
    ));
