import classNames from 'classnames';
import React, { ChangeEvent, forwardRef, ReactNode } from 'react';
import { Text, TextTypeEnum } from '../text/Text.component';
import './Checkbox.scss';

declare interface ICheckboxProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  isPartiallyChecked?: boolean;
  label?: string;
  onChange?: (event?: ChangeEvent<HTMLInputElement>) => void;
  size?: number;
  type?: TextTypeEnum;
  children?: ReactNode;
  name?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(({
  size, label, onChange, checked, children, className, type, name, isPartiallyChecked,
}, ref) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className={classNames('CheckboxComponent__checkboxWrapper', className)}>
    <input
      ref={ref}
      className={classNames('CheckboxComponent__checkboxInput', {'CheckboxComponent__checkboxInput-partiallyChecked': isPartiallyChecked })}
      checked={checked}
      name={name || label || 'checkbox'}
      type="checkbox"
      onChange={onChange}
    />
    <span className="CheckboxComponent__checkbox">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        viewBox="0 0 24 24"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points={isPartiallyChecked && !checked ? '0 12 12 12 24 12' : '4 12 9 17 20 6'} />
      </svg>
    </span>
    {label && !children ? <Text type={type}>{label}</Text> : children}
  </label>
));
