import classNames from 'classnames';
import React, { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { InlineError } from '../inline-error/InlineError.component';
import { InlineInfo } from '../inline-info/InlineInfo.component';
import { Text, TextTypeEnum } from '../text/Text.component';
import './TextArea.scss';

declare interface IInputProps {
  onChange?: (event?: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  name?: string;
  onBlur?: (event?: React.FocusEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  disabled?: boolean;
  errors?: object;
  placeholder?: string;
  info?: string;
  className?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  value?: string;
  autoResize?: boolean;
  minRows?: number;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, IInputProps>(({
  autoResize,
  minRows,
  disabled,
  required,
  placeholder,
  label,
  className = '',
  info,
  autoFocus,
  defaultValue = '',
  name = '',
  errors = {},
  onChange,
  onBlur,
}, ref) => {
  const fieldError = errors[name];
  const [requiredError, setRequiredError] = useState(false);
  const [val, setValue] = useState(defaultValue);

  // Functions
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: val } = event.target;
    onChange?.(event);
    setValue(val);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const requiredOutsideForm = !!onChange && !!required;
    setRequiredError(requiredOutsideForm && !val);
    onBlur?.(e);
  };

  return (
    <div className={classNames('TextAreaComponent__divWrapper', className)}>
      <Text type={TextTypeEnum.INPUT_LABEL}>{label}</Text>
      {autoResize ? (
        <TextareaAutosize
          ref={ref}
          className={classNames(
            'TextAreaComponent__inputElement',
            'TextAreaComponent__inputElement-resizable',
            { 'TextAreaComponent__inputElement-disabled': disabled },
            { 'TextAreaComponent__inputElement-error': fieldError || requiredError },
          )}
          name={name}
          autoFocus={autoFocus}
          onChange={ref ? undefined : handleOnChange}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          value={ref ? undefined : val}
          minRows={minRows}
        />
      ) : (
        <textarea
          className={classNames(
            'TextAreaComponent__inputElement',
            { 'TextAreaComponent__inputElement-disabled': disabled },
            { 'TextAreaComponent__inputElement-error': fieldError || requiredError },
          )}
          name={name}
          autoFocus={autoFocus}
          onChange={ref ? undefined : handleOnChange}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          ref={ref}
          value={ref ? undefined : val}
        />
      )}
      {fieldError || requiredError ? <InlineError label={label} fieldError={fieldError} /> : <InlineInfo info={info} />}
    </div>
  );
});
