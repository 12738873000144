import React from 'react';
import './Link.scss';

declare interface ILinkProps {
  href?: string;
  target?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
export const Link: React.FC<ILinkProps> = ({ href, target, onClick, children }) => (
  <div className="LinkComponent__wrapper" onClick={onClick}>
    <a href={href} target={target} className="LinkComponent__aTag">{children}</a>
  </div>
);