import React from 'react';

declare interface ICircleLoaderProps {
  width?: number;
  height?: number;
}
export const CircleLoader: React.FC<ICircleLoaderProps> = ({ width, height }) => (
  <svg data-testid="circleLoader-component-wrapper" style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }} width={width || '200px'} height={height || '200px'} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle cx="50" cy="50" r="30" stroke="#072912" strokeWidth="10" fill="none" />
    <circle cx="50" cy="50" r="30" stroke="#3fd171" strokeWidth="5" strokeLinecap="round" fill="none" transform="rotate(129.733 50 50)">
      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.0638297872340425s" values="0 50 50;180 50 50;720 50 50" keyTimes="0;0.5;1" />
      <animate attributeName="stroke-dasharray" repeatCount="indefinite" dur="1.0638297872340425s" values="18.84955592153876 169.64600329384882;65.97344572538566 122.52211349000191;18.84955592153876 169.64600329384882" keyTimes="0;0.5;1" />
    </circle>
  </svg>
);
