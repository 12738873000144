import React from 'react';
import { Text, TextTypeEnum } from '../text/Text.component';
import './InlineInfo.scss';

declare interface IInlineInfoProps {
  info?: string;
}

export const InlineInfo: React.FC<IInlineInfoProps> = ({ info }) => (
  <div className="InlineInfo__info">
    <Text type={TextTypeEnum.SMALL}>{info}</Text>
  </div>
);
