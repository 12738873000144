import classNames from 'classnames';
import React, { CSSProperties, FC, useMemo } from 'react';
import Select, { StylesConfig, ValueType } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Text, TextTypeEnum } from '../text/Text.component';
import './Dropdown.scss';

export interface IOption {
  label: string;
  value: string;
  isNew?: boolean;
}

export type SelectionValue = ValueType<IOption, false> | ValueType<IOption, true>;
interface OnChangeOption {
  label: string;
  value: string;
  __isNew__?: boolean;
}
declare interface IDropdownProps {
  label?: string;
  options: Array<IOption>;
  onChange: (selection: SelectionValue) => void;
  errors?: string[];
  disabled?: boolean;
  info?: string;
  value?: string[] | string | undefined | null;
  defaultValue?: string[] | string;
  className?: string;
  placeholder?: string;
  style?: CSSProperties | undefined
  isMulti?: boolean;
  isCreatable?: boolean;
  selectStyles?: StylesConfig<any, false> | StylesConfig<any, true>;
  transparent?: boolean;
  required?: boolean;
}
const getDefaultValue = (options: Array<IOption>, defaultValue?: string | string[], isMulti?: boolean) => {
  if (isMulti) {
    return options.filter(({ value: oValue }) => Array.isArray(defaultValue) ? defaultValue.includes(oValue) : oValue === defaultValue);
  }
  return options.find(({ value: oValue }) => oValue === defaultValue);
};

const getValue = (options: Array<IOption>, value?: string | string[] | null, isMulti?: boolean) => {
  if (value === undefined) return undefined;
  if (isMulti) {
    return options.filter(({ value: oValue }) => Array.isArray(value) ? value.includes(oValue) : oValue === value) ?? null;
  }
  return options.find(({ value: oValue }) => oValue === value) ?? null;
};

export const Dropdown: FC<IDropdownProps> = ({
  label,
  options,
  errors,
  disabled,
  onChange,
  info,
  value,
  className,
  style,
  defaultValue,
  placeholder,
  isMulti,
  selectStyles,
  isCreatable,
  transparent,
  required,
}) => {
  const hasErrors = !!errors?.length;
  const selectProps = useMemo(() => ({
    className: classNames('Dropdown__select', { 'Dropdown__select-error': hasErrors }),
    options,
    defaultValue: getDefaultValue(options, defaultValue, isMulti),
    value: getValue(options, value, isMulti),
    placeholder,
    isMulti,
    styles: selectStyles,
  }), [isMulti, hasErrors, options, value, placeholder, isMulti, selectStyles]);
  return (
    <div style={style} className={classNames('Dropdown__wrapper', { 'Dropdown__wrapper-transparent': transparent }, { 'Dropdown__wrapper-disabled': disabled }, className)}>
      {label && (
      <Text className="Dropdown__header" type={TextTypeEnum.INPUT_LABEL}>
        {label}
        {required ? <span>*</span> : ''}
      </Text>
      ) }
      <div className="Dropdown__selectWrapper">
        { isCreatable ? (
          <CreatableSelect
            {...selectProps}
            createOptionPosition="first"
            onChange={(selection) => {
              if (isMulti) {
                onChange((selection as Array<IOption>)?.map((selectedItem: IOption) => ({
                  isNew: (selectedItem as OnChangeOption)?.__isNew__, ...selectedItem,
                } as IOption)));
              } else {
                onChange({ isNew: (selection as OnChangeOption)?.__isNew__, ...selection } as SelectionValue);
              }
            }}
          />
        ) : (
          <Select
            {...selectProps}
            onChange={(selection) => onChange(selection as SelectionValue)}
          />
        )}
      </div>
      { hasErrors
        ? (
          <div className="Dropdown__errorList">
            {errors?.map((error) => <Text key={error} type={TextTypeEnum.SMALL}>{error}</Text>)}
          </div>
        )
        : (
          <div className="Dropdown__info">
            <Text type={TextTypeEnum.SMALL}>{info}</Text>
          </div>
        )}
    </div>
  );
};
