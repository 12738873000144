import * as React from 'react';
import classNames from 'classnames';
import './Chip.scss';

export enum IChipVariant {
  SUCCESS,
  FAILURE,
  NEUTRAL,
}

interface IChip {
  className?: string;
  variant?: IChipVariant;
}

export const Chip: React.FC<IChip> = ({ children, variant, className }) => (
  <div
    data-testid="chip-wrapper"
    className={classNames(
      'Chip__wrapper',
      { 'Chip__wrapper-success': variant === IChipVariant.SUCCESS },
      { 'Chip__wrapper-failure': variant === IChipVariant.FAILURE },
      { 'Chip__wrapper-neutral': variant === IChipVariant.NEUTRAL },
      className,
    )}
  >
    {children}
  </div>
);
