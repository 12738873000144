import React from 'react';
import {
  AlertTriangle, Check, Info, Trash2, X
} from 'react-feather';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Text, TextTypeEnum } from '../text/Text.component';
import './Toast.scss';

export enum ToastType {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFORMATIONAL = 'INFORMATIONAL',
  SUCCESS = 'SUCCESS',
  DELETE = 'DELETE'
}

declare interface IToastProps {
  show?: boolean;
  type: ToastType;
  message?: string;
  title?: string;
  autoClose?: false | number
}
export const Toast: React.FC<IToastProps> = ({
  show, type, message, title, autoClose
}) => {
  const ToastContent: React.FC = () => {
    const Icon = {
      WARNING: AlertTriangle,
      ERROR: X,
      INFORMATIONAL: Info,
      SUCCESS: Check,
      DELETE: Trash2
    }[type];
    return (
      <div className={`Toast__ToastContentWrapper-${type}`}>
        <Icon />
        <div>
          <Text type={TextTypeEnum.BODY_EMPHASIZED}>{title}</Text>
          <Text type={TextTypeEnum.SMALL}>{message}</Text>
        </div>
      </div>
    );
  };
  const CloseButton = ({ closeToast }: { closeToast: () => void}) => (
    <div className={`Toast__Button-${type}`} onClick={closeToast}><X /></div>
  );
  show && toast(<ToastContent />, {
    position: 'top-right',
    autoClose: autoClose ?? 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
  });
  return (
    <ToastContainer
      position="top-right"
      autoClose={autoClose ?? 3000}
      hideProgressBar={false}
      newestOnTop={false}
      rtl={false}
      draggable
      pauseOnHover
      closeOnClick={false}
      closeButton={CloseButton}
    />
  );
};
