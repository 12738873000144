import React, { useEffect, useState } from 'react';
import { Icon } from 'react-feather';
import classNames from 'classnames';
import { Text, TextTypeEnum } from '../text/Text.component';
import { InlineError } from '../inline-error/InlineError.component';
import { InlineInfo } from '../inline-info/InlineInfo.component';
import './Input.scss';

declare interface IInputProps {
  onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  triggerValidation?: boolean;
  validation?: { match: RegExp, error: string };
  errors?: object;
  errorsV1?: Array<string>;
  placeholder?: string;
  type?: string;
  info?: string;
  pattern?: RegExp;
  className?: string;
  autoFocus?: boolean;
  autoSelect?: boolean;
  icon?: Icon;
  defaultValue?: string;
  value?: string | undefined | null;
  style?: React.CSSProperties | undefined
  allowedInput?: RegExp;
  name?: string;
  transparent?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(({
  triggerValidation, validation, errors = {}, name = '', disabled, required, placeholder, type, label, onBlur, className = '', info, pattern, autoFocus, defaultValue = '', value, style, allowedInput, onChange, transparent, autoSelect, ...props
}, ref) => {
  const fieldError = errors[name];
  // temporary to account for required prop used in forms not migrated to react-hook-form.
  const [requiredError, setRequiredError] = useState(false)
  const [val, setValue] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);

  // Logic for stateless value
  // if value changed to empty default to empty value
  useEffect(() => {
    setValue(value !== undefined && value !== null ? value : defaultValue);
  }, [value, defaultValue]);


  // for auto-formatting fields like phone numbers and dates
  // the user can only input keys permitted by allowedInput, but special characters are allowed in the input value
  const onKeyPress = (e: any) => {
    if (!allowedInput) return;
    if (!e.key.match(allowedInput)) e.preventDefault();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: val } = event.target;
    if (val && pattern && !val.match(pattern)) return;
    onChange?.(event);
    setValue(val);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const requiredOutsideForm = !!onChange && !!required;
    setRequiredError(requiredOutsideForm && !val)
    setIsFocused(false);
    onBlur?.(e);
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (autoSelect && defaultValue) {
      e.target.select();
    }
    setIsFocused(true);
  };

  return (
    <div style={style} className={classNames('InputComponent__divWrapper', className, { 'InputComponent__divWrapper-withIcon': !!props.icon })}>
      <Text className="InputComponent__header" type={TextTypeEnum.INPUT_LABEL}>{label}<span>{required ? '*' : ''}</span></Text>
      {props.icon
        ? (
          <props.icon
            size={24}
            className={classNames(
              'InputComponent__icon',
              { 'InputComponent__icon-focused': isFocused },
            )}
          />
        )
        : null}
      <input
        className={classNames(
          'InputComponent__inputElement',
          { 'InputComponent__inputElement-transparent': transparent },
          { 'InputComponent__inputElement-withIcon': !!props.icon },
          { 'InputComponent__inputElement-disabled': disabled },
          { 'InputComponent__inputElement-error': fieldError || requiredError },
        )}
        name={name}
        required={required}
        disabled={disabled}
        onChange={ref ? undefined: handleOnChange}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        type={type}
        autoFocus={autoFocus}
        onFocus={handleOnFocus}
        ref={ref}
        value={ref ? undefined : val}
        onKeyPress={onKeyPress}
      />
      {fieldError || requiredError ? <InlineError label={label} fieldError={fieldError} /> : <InlineInfo info={info} />}
    </div>
  );
});
