import React from 'react';
import { Text, TextTypeEnum } from '../text/Text.component';
import './InlineError.scss';

export interface IInlineError {
  type: string;
  message: string;
}

declare interface IInlineErrorProps {
  fieldError: IInlineError;
  label: string;
}

export const InlineError: React.FC<IInlineErrorProps> = ({ fieldError, label }) => (
  <div className="InlineError__errorList">
    <Text type={TextTypeEnum.SMALL}>{fieldError?.message || `${label} is required.`}</Text>
  </div>
);
