import React, { useState } from 'react';
import classNames from 'classnames';
import { TextTypeEnum, Text } from '../text/Text.component';
import './Tabs.scss';

declare interface IProps {
  initialTab?: number;
  secondaryStyle?: boolean;
  tabs: {
    text: string
    onClick: (index: number) => void;
  }[]
}
export const Tabs: React.FC<IProps> = ({ tabs, initialTab, secondaryStyle }) => {
  const [selectedIndex, setSelectedIndex] = useState(initialTab ?? 0);
  const tabOnClick = (index: number, onClick: (index: number) => void) => {
    setSelectedIndex(index);
    onClick(index);
  };
  return (
    <div className="TabsWrapper">
      {tabs.map((tab, index) => <div onClick={() => tabOnClick(index, tab.onClick)} role="button" onKeyDown={() => tabOnClick(index, tab.onClick)} tabIndex={-1} key={tab.text} className={classNames('Tab', { secondaryStyle }, { selected: selectedIndex === index })}><Text type={selectedIndex === index ? TextTypeEnum.BODY_EMPHASIZED : TextTypeEnum.BODY}>{tab.text}</Text></div>)}
    </div>
  );
};
