import classNames from 'classnames';
import React from 'react';
import {
  ArrowUp, ChevronLeft, ChevronRight, Info, Search
} from 'react-feather';
import { Button } from '../button/Button.component';
import { ClickAwayListener } from '../click-away-listener';
import { Input } from '../input/Input.component';
import { Link } from '../link/Link.component';
import { Text, TextTypeEnum } from '../text/Text.component';

export enum RowDataType {
  STRING,
  NUMBER,
  DATE,
  ACTION,
}

export enum SortDirection {
  ASC,
  DESC,
}

export declare interface IGridProps<T = {}> {
  rows: Array<IRowItem<T>>
  columnDefs: IColumnDefs<T>;
  buttons?: Array<JSX.Element>;
  className?: string;
  onRowClick?: (id: string) => void;
  of?: string;
}

export declare interface IRowItem<T> {
  id: string;
  columnData: Array<{ data: IRowData<T> }>;
  disabled?: boolean;
}

export interface IRowData<T = {}> {
  id?: string;
  label?: string;
  item?: T;
}
declare interface IHeader<T> {
  title: string;
  type?: RowDataType;
  className?: string;
  render?: (renderData?: IRowData<T>) => JSX.Element;
}
export interface IColumnDefs<T> {
  spacing: string;
  headers: Array<IHeader<T>>
}
declare interface IGridComponentProps<T> extends IGridProps<T>{
  onSearch: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  sortColumn: number;
  onColumnClick: (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  sortDirection: SortDirection,
  total: number;
  pageOffset: number;
  incrementPage: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  decrementPage: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  pageSize: number;
  handlePageSizeClick: (size: number) => (e: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void;
  pageSizeSelectorOpen: boolean;
  hasNoData: boolean;
  openPageSizeSelector: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  closePageSizeSelector: () => void;
  onRowClick?: (id: string) => void;
  of: string;
}
export function GridComponent<T>({
  rows,
  columnDefs,
  buttons = [],
  className,
  onSearch,
  onColumnClick,
  sortColumn,
  sortDirection,
  total,
  pageOffset,
  decrementPage,
  incrementPage,
  pageSize,
  handlePageSizeClick,
  pageSizeSelectorOpen,
  openPageSizeSelector,
  closePageSizeSelector,
  onRowClick,
  hasNoData,
  of,
}: IGridComponentProps<T>) {
  return (
    <div className={className}>
      <div className="Grid__topRow" style={{ gridTemplateColumns: `212px 1fr repeat(${buttons.length}, auto)` }}>
        <Input label="" onChange={onSearch} placeholder="Search" icon={Search} />
        <div />
        {buttons}
      </div>
      <div>
        <div className="Grid__headerRow" style={{ gridTemplateColumns: columnDefs.spacing }}>
          {columnDefs.headers.map((header, index) => (
            <div key={header.title} onClick={header.type !== RowDataType.ACTION ? onColumnClick(index) : undefined} className="Grid__headerRowItemWrapper">
              <Text type={TextTypeEnum.BODY_EMPHASIZED}>{header.title}</Text>
              {sortColumn === index && (
                <ArrowUp
                  size={14}
                  className={classNames(
                    'Grid__headerRowSortDirection',
                    { 'Grid__headerRowSortDirection-desc': sortDirection === SortDirection.DESC },
                  )}
                  type={TextTypeEnum.BODY_EMPHASIZED}
                />
              )}
            </div>
          ))}
        </div>
        <div>
          {hasNoData && (
            <div className="Grid__noDataWrapper">
              <Info size="40" />
              <Text className="Grid__noData" type={TextTypeEnum.H5}>{`No ${of}`}</Text>
            </div>
          )}
          {rows.length
            ? rows.map(({ id, columnData, disabled }) => (
              <div key={id} className={classNames('Grid__rows', { 'Grid__rows-clickable': !!onRowClick }, { 'Grid__rows-disabled': disabled })} style={{ gridTemplateColumns: columnDefs.spacing }} onClick={() => onRowClick?.(id)}>
                {columnDefs.headers.map(({ render, className }, index) => render
                  ? render(columnData[index].data)
                  : <Text key={columnData[index].data.id} className={classNames('Grid__cell', className)} type={TextTypeEnum.BODY}>{columnData[index].data.label}</Text>)}
              </div>
            ))
            : !hasNoData && (
            <div className="Grid__noDataWrapper">
              <Search size="40" />
              <Text className="Grid__noData" type={TextTypeEnum.H5}>No results matched your search criteria</Text>
            </div>
            )}
        </div>
      </div>
      { !!total && (
      <div className="Grid__footer">
        <div className="Grid__paginationSection">
          <div className="Grid__paginationDisplay">
            <Text type={TextTypeEnum.BODY}>Displaying</Text>
            <Link onClick={openPageSizeSelector}>{rows.length}</Link>
            <Text type={TextTypeEnum.BODY}>{`out of ${total}`}</Text>
          </div>
          <div className="Grid__paginationSelection">
            <Text type={TextTypeEnum.BODY}>{`${rows.length > 0 ? pageOffset + 1 : 0} - ${pageOffset + rows.length} `}</Text>
            <Button icon onClick={decrementPage} type="SECONDARY" disabled={!pageOffset}><ChevronLeft /></Button>
            <Button icon onClick={incrementPage} type="SECONDARY" disabled={pageOffset + rows.length === total}><ChevronRight /></Button>
          </div>
        </div>
        <ClickAwayListener onClickAway={closePageSizeSelector} visible={pageSizeSelectorOpen}>
          <div className="Grid__pageSizeSelector">
            <Text
              onClick={handlePageSizeClick(5)}
              className={classNames({ Grid__selectedPageSizeText: pageSize === 5 })}
              type={TextTypeEnum.BODY}
            >
              5

            </Text>
            <Text
              onClick={handlePageSizeClick(10)}
              className={classNames({ Grid__selectedPageSizeText: pageSize === 10 })}
              type={TextTypeEnum.BODY}
            >
              10

            </Text>
            <Text
              onClick={handlePageSizeClick(25)}
              className={classNames({ Grid__selectedPageSizeText: pageSize === 25 })}
              type={TextTypeEnum.BODY}
            >
              25

            </Text>
            <Text
              onClick={handlePageSizeClick(50)}
              className={classNames({ Grid__selectedPageSizeText: pageSize === 50 })}
              type={TextTypeEnum.BODY}
            >
              50

            </Text>
            <Text
              onClick={handlePageSizeClick(100)}
              className={classNames({ Grid__selectedPageSizeText: pageSize === 100 })}
              type={TextTypeEnum.BODY}
            >
              100

            </Text>
          </div>
        </ClickAwayListener>
      </div>
      )}
    </div>
  );
}
