import classNames from 'classnames';
import React, { useRef } from 'react';
import { CircleLoader } from '../circle-loader/CircleLoader.component';
import './Button.scss';

declare interface IButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'PRIMARY' | 'SECONDARY' | 'ERROR';
  disabled?: boolean;
  icon?: boolean;
  loading?: boolean;
  className?: string;
  submit?: boolean;
  small?: boolean;
  tabIndex?: number;
}

function sentenceCase<T>(str: T) {
  if (typeof str !== 'string') {
    return str;
  }
  const formattedStr = str.toLowerCase();
  return formattedStr[0].toUpperCase() + formattedStr.slice(1);
}

type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
const noop = () => {};
export const Button: React.FC<IButtonProps> = ({
  onClick, type, disabled, icon, loading, small, className = '', submit, children, tabIndex = 0
}) => {
  // Reduce possibility of a double click
  const clickedRef = useRef(false);
  const clickHandler = (e: ClickEvent) => {
    if (!clickedRef.current) {
      clickedRef.current = true;
      void onClick?.(e);
      setTimeout(() => {
        clickedRef.current = false;
      }, 500);
    }
  };
  return (
    <button
      className={classNames(
        'ButtonComponent__btnfocusOutline',
        { 'ButtonComponent__btn-disabled': disabled },
        className,
      )}
      data-testid="button-component-wrapper"
      role="menuitem"
      tabIndex={tabIndex}
      type={submit ? 'submit' : 'button'}
      onClick={loading || disabled ? undefined : clickHandler}
      onDoubleClick={noop}
    >
      <div
        className={classNames(
          `ButtonComponent__btn-${type || 'PRIMARY'}`,
          { 'ButtonComponent__btn-icon': icon },
          { 'ButtonComponent_btn-loading': loading },
          { 'ButtonComponent__btn-small': small },
        )}
        data-testid="button-component-div"
      >
        {loading && <CircleLoader height={30} width={30} />}
        {sentenceCase(children)}
      </div>
    </button>
  );
};
