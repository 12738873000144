import React from 'react';
import './Text.scss';

export enum TextTypeEnum {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  BODY_EMPHASIZED = 'body-emphasized',
  INPUT_MASK = 'input-mask',
  BODY = 'body',
  CAPTION = 'caption',
  INPUT_LABEL = 'input-label',
  SMALL = 'small',
}

declare interface ITextProps {
  type?: TextTypeEnum;
  className?: string;
  hoverLabel?: string;
  onClick?: (e: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void;
  style?: React.CSSProperties
}
export const Text: React.FC<ITextProps> = ({
  type = TextTypeEnum.BODY, className = '', children, onClick, style, hoverLabel,
}) => (
  <h1 data-testid="text-component-wrapper" style={style} className={`InputComponent__${type} ${className}`} onClick={onClick} title={hoverLabel ?? children?.toString()}>
    {children}
  </h1>
);
